import React, { useState } from "react";
import * as XLSX from "xlsx";

const AttendanceDetails = () => {
  const [selectedDate, setSelectedDate] = useState("");
  const [attendanceData, setAttendanceData] = useState({});
  const [error, setError] = useState("");
  const [isDataFetched, setIsDataFetched] = useState(false);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const fetchAttendanceDetails = async () => {
    try {
      setIsDataFetched(true);
      const token = localStorage.getItem("token");
      const response = await fetch(
        "https://backendsb.marsbpo.org/api/attendance/getAttendanceDetailsByDate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ date: selectedDate }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch attendance details");
      }

      const data = await response.json();
      setAttendanceData(data.attendanceDetails);
      if (Object.values(data.attendanceDetails).some((arr) => arr.length > 0)) {
        setError("");
      } else {
        setError("No attendance data available for the selected date.");
      }
    } catch (error) {
      console.error("Error fetching attendance details:", error.message);
      setError("Error fetching attendance details. Please try again.");
    }
  };

  const exportToExcel = () => {
    if (!attendanceData) {
      return;
    }

    const workbook = XLSX.utils.book_new();

    Object.keys(attendanceData).forEach((type) => {
      const worksheet = XLSX.utils.json_to_sheet(attendanceData[type]);
      XLSX.utils.book_append_sheet(workbook, worksheet, `${type}Employees`);
    });

    XLSX.writeFile(workbook, `AttendanceDetails_${selectedDate}.xlsx`);
  };

  const getColorClass = (type) => {
    switch (type) {
      case "present":
        return "bg-green-100 text-green-800";
      case "absent":
        return "bg-red-100 text-red-800";
      case "late":
        return "bg-purple-100 text-purple-800";
      case "leave":
        return "bg-yellow-100 text-yellow-800";
      default:
        return "";
    }
  };

  return (
    <div className="w-full md:w-3/4 lg:w-1/2 mx-auto p-6 bg-white border-t-4 border-blue-950 rounded-lg shadow-lg mt-8 relative">
    <div className="mb-6 flex flex-col md:flex-row md:items-center md:justify-between">
      <label htmlFor="dateInput" className="text-lg font-semibold mb-2 md:mb-0 md:text-right md:w-1/4">
        Select Date:
      </label>
      <div className="flex items-center md:w-3/4">
        <input
          type="date"
          id="dateInput"
          value={selectedDate}
          onChange={handleDateChange}
          className="border p-2 rounded-md flex-grow"
        />
        <button
          onClick={fetchAttendanceDetails}
          className="px-4 py-2 bg-blue-950 text-white rounded-md ml-2 md:ml-4"
        >
          Search
        </button>
      {
        isDataFetched && (
          <button
          onClick={exportToExcel}
          className="px-4 py-2 bg-green-500 text-white rounded-md ml-2 md:ml-4"
        >
          Export to Excel
        </button>
        )
      }
      </div>
    </div>
  
    {error && (
      <div className="mt-4 text-red-500">
        <p>{error}</p>
      </div>
    )}
  
    {isDataFetched && !error && (
      <div>
        {Object.keys(attendanceData).length > 0 ? (
          <div>
            <h2 className="text-2xl font-bold mb-4">
              Attendance Summary for {selectedDate}
            </h2>
            {Object.keys(attendanceData).map((type) => (
              <div
                key={type}
                className={`p-4 rounded-md mb-4 ${getColorClass(type)}`}
              >
                <h3 className="text-lg font-bold mb-2">
                  {type.charAt(0).toUpperCase() + type.slice(1)} Employees (
                  {attendanceData[type].length})
                </h3>
                <ul>
                  {attendanceData[type].map((employee) => (
                    <li key={employee.id} className="mb-1">
                      <strong>{employee.name}</strong> - Status: {employee.status}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        ) : (
          <div className="mt-4 text-red-500">
            <p>No attendance data available for the selected date.</p>
          </div>
        )}
      </div>
    )}
  </div>
  
  
  );
};

export default AttendanceDetails;
