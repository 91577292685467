import React, { useState } from 'react';
import axios from 'axios';
import AttendanceSearch from './AttendanceSearch';
import AttendanceDetails from './AttendanceDetails';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import Reports from './Reports';

const Attendance = () => {
  const [file, setFile] = useState(null);
  const [selectedButton, setSelectedButton] = useState('upload');
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      setModalContent({ type: 'error', message: 'Please select a file before uploading.' });
      setModalVisible(true);
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      const token = localStorage.getItem('token');
      const apiUrl = `https://backendsb.marsbpo.org/api/attendance/upload`;

      const response = await axios.post(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      setLoading(false);
      setFile(null);
      setModalContent({ type: 'success', message: 'File upload successful!' });
      setModalVisible(true);
      console.log('File upload successful:', response.data);
    } catch (error) {
      setLoading(false);
      setModalContent({ type: 'error', message: error.response ? error.response.data : error.message });
      setModalVisible(true);
      console.error('File upload failed:', error.response ? error.response.data : error.message);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <div className="container mx-auto p-8">
      <div className="flex flex-col md:flex-row md:space-x-4 md:space-y-0 mb-4">
        <button
          className={`flex-1 py-2 px-4 rounded focus:outline-none ${
            selectedButton === 'upload' ? 'bg-blue-950 text-white shadow-md hover:shadow-lg transition duration-300 ease-in-out' : 'bg-gray-300 text-gray-600'
          }`}
          onClick={() => setSelectedButton('upload')}
        >
          Upload Attendance
        </button>
        <button
          className={`flex-1 py-2 px-4 rounded focus:outline-none ${
            selectedButton === 'search' ? 'bg-blue-950 text-white shadow-md hover:shadow-lg transition duration-300 ease-in-out' : 'bg-gray-300 text-gray-600'
          }`}
          onClick={() => setSelectedButton('search')}
        >
          Search Attendance
        </button>
        <button
          className={`flex-1 py-2 px-4 rounded focus:outline-none ${
            selectedButton === 'details' ? 'bg-blue-950 text-white shadow-md hover:shadow-lg transition duration-300 ease-in-out' : 'bg-gray-300 text-gray-600'
          }`}
          onClick={() => setSelectedButton('details')}
        >
          Export Daily Attendance
        </button>
        <button
          className={`flex-1 py-2 px-4 rounded focus:outline-none ${
            selectedButton === 'report' ? 'bg-blue-950 text-white shadow-md hover:shadow-lg transition duration-300 ease-in-out' : 'bg-gray-300 text-gray-600'
          }`}
          onClick={() => setSelectedButton('report')}
        >
          Generate PDF Reports
        </button>
      </div>

      {selectedButton === 'upload' && (
        <div className="flex justify-center">
          <div
            className={`w-full lg:mt-12 md:w-3/4 lg:w-1/2  p-6 rounded-md shadow-md hover:shadow-lg transition duration-300 ease-in-out ${
              selectedButton === 'upload' ? 'border-t-4 border-blue-950' : ''
            }`}
          >
            <h2 className="text-2xl font-bold mb-4">Attendance Uploader</h2>
            <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} className="mb-4" />
            <button
              onClick={handleUpload}
              className={`w-full bg-blue-950 text-white py-2 px-4 rounded focus:outline-none ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              } hover:bg-opacity-75 focus:shadow-md transition duration-300 ease-in-out`}
              disabled={loading}
            >
              {loading ? 'Uploading...' : 'Upload'}
            </button>
          </div>
        </div>
      )}

      {selectedButton === 'search' && <AttendanceSearch />}

      {selectedButton === 'details' && <AttendanceDetails />}
      {selectedButton === 'report' && <Reports />}

      {modalVisible && (
        <div className="fixed inset-0 z-10 overflow-y-auto flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-xl overflow-hidden sm:max-w-md w-full">
            <div className="p-6">
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:h-10 sm:w-10">
                {modalContent.type === 'success' ? (
                  <FaCheckCircle className="h-6 w-6 text-green-600" />
                ) : (
                  <FaExclamationCircle className="h-6 w-6 text-red-600" />
                )}
              </div>
              <div className="mt-3 text-center">
                <h3 className="text-lg font-medium text-gray-900">{modalContent.type === 'success' ? 'Success' : 'Error'}</h3>
                <div className="mt-2">
                  <p className={`text-sm ${modalContent.type === 'success' ? 'text-green-500' : 'text-red-500'}`}>
                    {typeof modalContent.message === 'object' ? modalContent.message.message : modalContent.message}
                  </p>
                </div>
              </div>
              <div className="mt-5 sm:mt-4">
                <button
                  type="button"
                  onClick={closeModal}
                  className={`inline-flex justify-center w-full border border-transparent rounded-md shadow-sm px-4 py-2 text-sm font-medium focus:outline-none ${
                    modalContent.type === 'success' ? 'bg-green-600 text-white hover:bg-green-700' : 'bg-red-600 text-white hover:bg-red-700'
                  }`}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Attendance;
