import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';

const EmployeeCountChart = () => {
  const [options, setOptions] = useState({
    chart: {
      width: 380,
      type: 'donut',
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
    },
    legend: {
      formatter: function (val, opts) {
        return val + ' - ' + opts.w.globals.series[opts.seriesIndex];
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  });
  const [series, setSeries] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://backendsb.marsbpo.org/api/employees/department-employee-count', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const departmentNames = response.data.map((item) => item.department);
        const employeeCounts = response.data.map((item) => item.count);
  
        setOptions((prevOptions) => ({
          ...prevOptions,
          labels: departmentNames,
        }));
        setSeries(employeeCounts);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);
  
  

  return (
    <div style={{ backgroundColor: '#F4F4F4'}} className="flex flex-col md:col-span-2 md:row-span-2 shadow-lg rounded-lg overflow-hidden hover:shadow-lg transition duration-400 ease-in-out">
      <div className="px-6 py-4 text-lg border-b border-gray-200 bg-gray-100 font-bold">
        Employees Count in Each Department
      </div>
      <div className="p-4 flex-grow">
        <ReactApexChart options={options} series={series} type="donut" height={400} />
      </div>
    </div>
  );
};

export default EmployeeCountChart;
