import React, { useState, useEffect } from "react";
import axios from "axios";

const EmployeeFormUpload = () => {
  const [formData, setFormData] = useState({
    employeeName: "",
    dateOfJoining: "",
    fatherName: "",
    role: "",
    selectedDepartment: "",
    salary: "",
    email: "",
    cnic: "",
    emergencyContact: ""
  });
  const [departments, setDepartments] = useState([]);
  const [errors, setFormErrors] = useState({});
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://backendsb.marsbpo.org/api/attendance/getDepartments",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDepartments(response.data);
      } catch (error) {
        console.error(error);
        setFormErrors({ department: "Error fetching departments. Please try again." });
      }
    };

    fetchDepartments();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    setFormErrors({
      ...errors,
      [e.target.name]: ""
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.employeeName.trim()) {
      newErrors.employeeName = "Employee name is required";
    }

    if (!formData.dateOfJoining.trim()) {
      newErrors.dateOfJoining = "Date of joining is required";
    }

    if (!formData.fatherName.trim()) {
      newErrors.fatherName = "Father's name is required";
    }

    if (!formData.role.trim()) {
      newErrors.role = "Role is required";
    }

    if (!formData.selectedDepartment) {
      newErrors.selectedDepartment = "Department is required";
    }

    if (!formData.salary.trim()) {
      newErrors.salary = "Salary is required";
    }
    
    if (!formData.cnic.trim()) {
      newErrors.cnic = "CNIC is required";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      newErrors.email = "Invalid email format";
    }

    if (!formData.emergencyContact.trim()) {
      newErrors.emergencyContact = "Emergency contact is required";
    }

    setFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const isValidEmail = (email) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = JSON.stringify(formData)

    try {
      if (!validateForm()) {
        setLoading(false);
        return;
      }

      const token = localStorage.getItem("token");
      const response = await axios.post(
        "https://backendsb.marsbpo.org/api/employees/create",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        // Show success modal
        setSuccessModal(true);
        setFormData({
          employeeName: "",
          dateOfJoining: "",
          fatherName: "",
          role: "",
          selectedDepartment: "",
          salary: "",
          email: "",
          emergencyContact: "",
          cnic:""
        });
      } else {
        throw new Error("Failed to add employee");
      }
    } catch (error) {
      console.error("Error adding employee:", error.message);
      // Show error message
      setFormErrors({ submit: "Failed to add employee. Please try again." });
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setSuccessModal(false);
  };

  return (
    <div className="container mx-auto p-8">
    <form
      style={{ backgroundColor: '#F4F4F4' }}
      className="max-w-2xl mx-auto p-12 shadow-md"
      onSubmit={handleSubmit}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        {/* Name */}
        <div>
          <label htmlFor="employeeName" className="block text-sm font-medium text-blue-900">
            Name
          </label>
          <input
            type="text"
            id="employeeName"
            name="employeeName"
            value={formData.employeeName}
            onChange={handleChange}
            className="mt-1 p-2 w-full border-b focus:outline-none"
            placeholder="Employee Name"
          />
          {errors.employeeName && (
            <p className="text-red-500 text-xs mt-1">{errors.employeeName}</p>
          )}
        </div>
  
        {/* Date of Joining */}
        <div>
          <label htmlFor="dateOfJoining" className="block text-sm font-medium text-blue-900">
            Date of Joining
          </label>
          <input
            type="date"
            id="dateOfJoining"
            name="dateOfJoining"
            value={formData.dateOfJoining}
            onChange={handleChange}
            className="mt-1 p-2 w-full border-b focus:outline-none"
          />
          {errors.dateOfJoining && (
            <p className="text-red-500 text-xs mt-1">{errors.dateOfJoining}</p>
          )}
        </div>
  
        {/* Father's Name */}
        <div>
          <label htmlFor="fatherName" className="block text-sm font-medium text-blue-900">
            Father's Name
          </label>
          <input
            type="text"
            id="fatherName"
            name="fatherName"
            value={formData.fatherName}
            onChange={handleChange}
            className="mt-1 p-2 w-full border-b focus:outline-none"
            placeholder="Father's Name"
          />
          {errors.fatherName && (
            <p className="text-red-500 text-xs mt-1">{errors.fatherName}</p>
          )}
        </div>
  
        {/* Role */}
        <div>
          <label htmlFor="role" className="block text-sm font-medium text-blue-900">
            Role
          </label>
          <input
            type="text"
            id="role"
            name="role"
            value={formData.role}
            onChange={handleChange}
            className="mt-1 p-2 w-full border-b focus:outline-none"
            placeholder="Role"
          />
          {errors.role && (
            <p className="text-red-500 text-xs mt-1">{errors.role}</p>
          )}
        </div>
  
        {/* Department */}
        <div>
          <label htmlFor="selectedDepartment" className="block text-sm font-medium text-blue-900">
            Department
          </label>
          <select
            id="selectedDepartment"
            name="selectedDepartment"
            value={formData.selectedDepartment}
            onChange={handleChange}
            className="mt-1 p-2 w-full border-b focus:outline-none"
          >
            <option value="">Select Department</option>
            {departments.map((department) => (
              <option key={department} value={department.department}>
                {department.department}
              </option>
            ))}
          </select>
          {errors.selectedDepartment && (
            <p className="text-red-500 text-xs mt-1">{errors.selectedDepartment}</p>
          )}
        </div>
  
        {/* Salary */}
        <div>
          <label htmlFor="salary" className="block text-sm font-medium text-blue-900">
            Salary
          </label>
          <input
            type="number"
            id="salary"
            name="salary"
            value={formData.salary}
            onChange={handleChange}
            className="mt-1 p-2 w-full border-b focus:outline-none"
            placeholder="Salary"
          />
          {errors.salary && (
            <p className="text-red-500 text-xs mt-1">{errors.salary}</p>
          )}
        </div>
  
        {/* Email */}
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-blue-900">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="mt-1 p-2 w-full border-b focus:outline-none"
            placeholder="Email"
          />
          {errors.email && (
            <p className="text-red-500 text-xs mt-1">{errors.email}</p>
          )}
        </div>
  
        {/* CNIC */}
        <div>
          <label htmlFor="cnic" className="block text-sm font-medium text-blue-900">
            CNIC
          </label>
          <input
            type="text"
            id="cnic"
            name="cnic"
            value={formData.cnic}
            onChange={handleChange}
            className="mt-1 p-2 w-full border-b focus:outline-none"
            placeholder="Cnic"
          />
          {errors.cnic && (
            <p className="text-red-500 text-xs mt-1">{errors.cnic}</p>
          )}
        </div>
  
        {/* Emergency Contact */}
        <div>
          <label htmlFor="emergencyContact" className="block text-sm font-medium text-blue-900">
            Emergency Contact
          </label>
          <input
            type="text"
            id="emergencyContact"
            name="emergencyContact"
            value={formData.emergencyContact}
            onChange={handleChange}
            className="mt-1 p-2 w-full border-b focus:outline-none"
            placeholder="Emergency Contact"
          />
          {errors.emergencyContact && (
            <p className="text-red-500 text-xs mt-1">{errors.emergencyContact}</p>
          )}
        </div>
  
        {/* Submit Button */}
        <div className="col-span-2">
          <button
            type="submit"
            className="w-full bg-blue-950 text-white p-2 rounded-md"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>
    </form>
  
    {successModal && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
        <div className="bg-white p-8 rounded-lg shadow-md">
          <p className="text-green-500 text-lg mb-4">Employee added successfully!</p>
          <button
            onClick={closeModal}
            className="text-indigo-500 hover:underline text-lg"
          >
            Close
          </button>
        </div>
      </div>
    )}
  </div>
  
  );
};

export default EmployeeFormUpload;
